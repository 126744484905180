/* variable */
:root {
  --primary: #f8d754;
  --secondary: #2d3372;
}
/* base style */
* {
  box-sizing: border-box;
}
div.calculator {
  padding-bottom: 35px;
  padding-top: 20px; 
  max-width: 70vh;
  margin: 0 auto;
  border: 3px solid white;
  border-radius: 30px;
}
div#display {
  color: #fffffe;
  text-align: right;
  font-size: 4em;
  overflow-x: scroll;
  margin: 5px;
}
div.divider {
    background-color: rgba(187, 176, 212, 0.5);
    height: 3px;
    margin-bottom: 20px;
}
.calculator-buttons .btn, #seven, #add, #three, #divide, #one, #clear {
  padding: 0px;
  line-height: 0px;
  background: var(--secondary);
  border: 3px solid #9893d8 ; 
  width: 10vh;
  height: 10vh;
  color: #fffffe; 
  border-radius: 100px;
  font-size: 4vh; 
  cursor: pointer; 
}
.calculator-buttons {
  padding: 20px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

#zero {
  width: 25vh;
  grid-column: span 2;
}
#equal {
  height: 22vh;
  grid-row: span 2;                                         ;
}
#clear   {
  border: 3px solid var(--primary);
}

#add, #substract, #multiply, #divide, #equal {
  background: var(--primary);
  color: var(--secondary);
}

.sidenav-overlay {
  height: 0px;
  width: 0px;
}
@media only screen and (max-width: 415px) {
  button#zero {
    width: 22vh;
  }
}
@media only screen and (max-width: 375px) {
  .calculator-buttons {
    grid-gap: 5px;
  }
}
@media only screen and (max-width: 375px) {
  .calculator-buttons {
    grid-gap: 0px;
    padding: 20px 0px;
  }
  button#zero {
    width: 20vh;
  }
}