
.flexbox {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  flex-wrap: wrap;
}
div.customize {
  margin: 0px 50px;
}
#color-button {
    margin-left: 20px;
}
input {
  margin-top: 20px;
}
button#color-button {
  white-space: nowrap;
}
